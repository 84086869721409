exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-trendbook-js": () => import("./../../../src/pages/trendbook.js" /* webpackChunkName: "component---src-pages-trendbook-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-finder-js": () => import("./../../../src/templates/product-finder.js" /* webpackChunkName: "component---src-templates-product-finder-js" */),
  "component---src-templates-product-formula-finder-js": () => import("./../../../src/templates/product-formula-finder.js" /* webpackChunkName: "component---src-templates-product-formula-finder-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "slice---src-components-footer-jsx": () => import("./../../../src/components/footer.jsx" /* webpackChunkName: "slice---src-components-footer-jsx" */),
  "slice---src-components-header-header-jsx": () => import("./../../../src/components/Header/Header.jsx" /* webpackChunkName: "slice---src-components-header-header-jsx" */),
  "slice---src-components-sliced-elements-job-openings-js": () => import("./../../../src/components/SlicedElements/JobOpenings.js" /* webpackChunkName: "slice---src-components-sliced-elements-job-openings-js" */),
  "slice---src-components-sliced-elements-latest-news-events-js": () => import("./../../../src/components/SlicedElements/LatestNewsEvents.js" /* webpackChunkName: "slice---src-components-sliced-elements-latest-news-events-js" */),
  "slice---src-components-sliced-elements-products-applications-js": () => import("./../../../src/components/SlicedElements/ProductsApplications.js" /* webpackChunkName: "slice---src-components-sliced-elements-products-applications-js" */)
}

